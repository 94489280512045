import React, { useState, useEffect, useContext } from 'react'
import {
    Box,
    Grid,
    Typography,
    Button,
    Container,
    Card,
    CardMedia,
    Divider,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import endpoints from "../Endpoints/endpoints";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./Ticket.css";
import { AdminProfileContext } from "../contexts/AdminContext";
import DirectionsIcon from '@mui/icons-material/Directions';
import img from "../assests/logo/logo.png"
import tickectimg from "../assests/ticket/tickect.png";


const NewTicket = () => {
    const { adminProfile } = useContext(AdminProfileContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [bookingData, setBookingData] = useState(null);
    const [qrCodeURL, setQRCodeURL] = useState("");
    const [plansTitle, setPlansTitle] = useState("");
    const [timing, setTiming] = useState("");
    const [subpackageName, setSubpackageName] = useState("");
    const [subtotal, setSubtotal] = useState(0);
    const [gst, setGst] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [adultActivities, setAdultActivities] = useState([]);
    const [childActivities, setChildActivities] = useState([]);
    const [showChildActivities, setShowChildActivities] = useState(false);
    const [userData, setUserData] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);

    const fetchBookingData = () => {
        const booking = location.state?.booking;
        const user = location.state?.userData || {};

        if (booking) {
            setBookingData(booking);
            setUserData(user);

            setLoading(false);
        } else {
            setLoading(false);
            navigate("/");
        }
    };

    const fetchPlans = async () => {
        if (bookingData) {
            try {
                const response = await axios.get(
                    `${endpoints.serverBaseURL}/api/plan/${bookingData.planId}`
                );
                const planData = response.data.plan;
                setPlansTitle(planData.title);
                setTiming(planData.timing);

                const subpackage = bookingData.selectedSubPackage;
                console.log("selected subpackage is ", subpackage);

                if (subpackage) {
                    setSubpackageName(subpackage.name);
                    setAdultActivities(subpackage.adult_activities || []);
                    setChildActivities(subpackage.child_activities || []);

                    setShowChildActivities(subpackage.child_activities && subpackage.child_activities.length > 0);

                    // Calculate subtotal, GST, and total amount
                    const subtotal =
                        subpackage.adult_price * bookingData.adult +
                        subpackage.child_price * bookingData.children;
                    const gst = subtotal * 0.18;
                    const totalAmount = subtotal + gst;

                    setSubtotal(subtotal);
                    setGst(gst);
                    setTotalAmount(totalAmount);
                } else {
                    console.error(
                        "No matching subpackage found for planTitle:",
                        bookingData.planTitle
                    );
                }
            } catch (error) {
                console.error("Error fetching plans", error);
            }
        }
    };

    const fetchQRCodeURL = async () => {
        try {
            const response = await axios.post(
                `${endpoints.serverBaseURL}/api/generateqr`,
                { text: bookingData._id }
            );
            setQRCodeURL(response.data.qrCodeURL);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching QR code Base64 data:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBookingData();
    }, [location.state?.booking]);

    useEffect(() => {
        if (bookingData) {
            fetchQRCodeURL();
            fetchPlans();
        }
    }, [bookingData]);

    const handleDownloadPDF = async () => {
        const ticketElement = document.getElementById("ticket-container");
        if (!ticketElement) {
            console.error("Element with ID 'ticket-container' not found");
            return;
        }

        html2canvas(ticketElement, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
            const ratio = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);
            const imgWidth = canvasWidth * ratio;
            const imgHeight = canvasHeight * ratio;
            const x = (pdfWidth - imgWidth) / 2;
            const y = (pdfHeight - imgHeight) / 2;
            pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
            pdf.save("Bharti_Resort-ticket.pdf");
        });
    };
    const sendPdfToServer = async () => {
        try {
            const ticketElement = document.getElementById("ticket-container");

            if (!ticketElement) {
                console.error("Element with ID 'ticket-container' not found");
                return;
            }

            // Convert the PDF to an image using html2canvas
            const canvas = await html2canvas(ticketElement, { scale: 2 });
            const imageBlob = await new Promise((resolve) =>
                canvas.toBlob((blob) => resolve(blob), "image/png")
            );

            // Prepare FormData with the image and phone number
            const formData = new FormData();
            formData.append("image", imageBlob, "ticket.png");

            if (userData?.phone || plansTitle || subpackageName) {
                formData.append("phone", userData.phone);
                formData.append("Plan", plansTitle)
                formData.append("subpackage", subpackageName)
                formData.append("Total amount", totalAmount)
                formData.append("Date", formattedDate)
                formData.append("Booking ID", bookingData._id)
            } else {
                console.error("Phone number is not available");
            }

            // Send the image to the server
            const response = await axios.post("http://localhost:5000/api/receive-image", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            console.log("Response from server:", response);

            if (response.status === 200) {
                alert("Image sent to the server successfully.");
            } else {
                console.error("Error sending image:", response);
            }
        } catch (error) {
            console.error("Error in sending image to server:", error.message);
            alert("Failed to send the image to the server.");
        }
    };

    // Autodownload PDF
    useEffect(() => {
        if (!loading && bookingData) {
            setTimeout(() => {
                handleDownloadPDF();
            }, 1000);
        }
    }, [loading, bookingData]);

    if (loading) {
        return <div>Loading...</div>;
    }

    // const formattedDate = bookingData?.bookingDate
    // ? bookingData.bookingDate.substring(0, 10)
    // : Date.now(); 
    const formattedDate = selectedDate
        ? selectedDate.toISOString().substring(0, 10) // Format selected date
        : bookingData?.bookingDate
            ? bookingData.bookingDate.substring(0, 10)
            : new Date().toISOString().substring(0, 10);
    console.log("B Date", formattedDate);

    return (
        <div
            className=''
            style={{ padding: "30px 2px", display: "flex", justifyContent: "center",  }}
        >
            <Box
                id="ticket-container"
                sx={{
                    width: { xs: "100%", md: "50%", },
              
                    background: "linear-gradient(to left, #7f9c40, #165b82)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    px: 1,
                    py: 1,
                }}
            >
                <Card
                    sx={{
                        width: { xs: "100%", md: "100%" },
                        maxWidth: "1080px",
                        borderRadius: 5,
                        bgcolor: "white",
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                        p: 2,
                    }}
                >
                    {/* Logo Section */}
                    <Box sx={{ display: "flex", justifyContent: "center", mb: 0 }}>
                        <CardMedia
                            component="img"
                            image={img}
                            alt="Logo"
                            sx={{
                                height: { xs: 100, sm: 90 },
                                width: "auto",
                            }}
                        />
                    </Box>

                    {/* Confirmation Section */}
                    <Box sx={{ textAlign: "center" }}>
                        <Typography variant="h6" fontWeight="medium" color={'#d77829'}>
                            Your Booking is confirmed!
                        </Typography>
                        <Typography variant="h6" color="#000000" mt={.5}>
                            BOOKING ID :{bookingData?._id}
                        </Typography>
                    </Box>

                    {/* Details Section */}
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            p: 2,
                            borderRadius: 4,
                            background: `url(${tickectimg})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                        <Grid item xs={6} md={8}>
                            <Box sx={{ mb: 1 }}>
                                <Typography variant="h6" color="white" fontSize={18}>
                                    NAME
                                </Typography>
                                <Typography variant="body1" color="white" fontSize={16}>
                                    {userData?.name ?
                                        userData.name.charAt(0).toUpperCase() + userData.name.slice(1) :
                                        "N/A"
                                    }
                                </Typography>
                            </Box>
                            <Box sx={{ mb: 1 }}>
                                <Typography variant="h6" color="white" fontSize={18}>
                                    CONTACT
                                </Typography>
                                <Typography variant="body1" color="white" fontSize={16}>
                                    {userData?.phone || "N/A"}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h6" color="white" fontSize={18}>
                                    EMAIL
                                </Typography>
                                <Typography variant="body1" color="white"
                                    fontSize={16}  >
                                    {userData?.email || "N/A"}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <img
                                src={`data:image/png;base64,${qrCodeURL}`}
                                alt="QR Code"
                                className="qr-code-large"
                                style={{
                                    width: 130,
                                    height: 130,
                                    backgroundColor: "#d9d9d9",
                                    borderRadius: 4,
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* Combined Details Section */}
                    <Box
                        sx={{
                            padding: 3,
                            borderRadius: 2,
                            boxShadow: 1,
                            bgcolor: "white",
                            border: '5px solid green',
                            position: 'relative',
                            mb: 0,
                        }}
                    >
                        {/* Plan Details Section */}
                        <Box
                            sx={{
                                padding: 1,
                                bgcolor: "white",
                            }}
                        >
                            <Box />
                            <Grid container spacing={1}>
                                <Grid item xs={6} >
                                    <Typography variant="h6" color="primary">
                                        PLAN
                                    </Typography>
                                    <Typography variant="h6" fontWeight="bold">
                                        {plansTitle}
                                    </Typography>
                                    <Typography variant="body2">{timing.fromtime &&
                                        timing.fromperiod &&
                                        timing.totime &&
                                        timing.toperiod ? (
                                        <span>
                                            {timing.fromtime} {timing.fromperiod} - {timing.totime}{" "}
                                            {timing.toperiod}
                                        </span>
                                    ) : (
                                        <span>No timing information available</span>
                                    )}</Typography>
                                    <Typography mt={3}>
                                        <span style={{ color: "#0071bc", fontWeight: "bold" }}>
                                            ADULTS:{" "}
                                        </span>
                                        <span style={{ color: "#666" }}>{bookingData?.adult}</span>
                                    </Typography>
                                    <Typography>
                                        <span style={{ color: "#0071bc", fontWeight: "bold" }}>
                                            CHILDREN:{" "}
                                        </span>
                                        <span style={{ color: "#666" }}>{bookingData?.children}</span>
                                    </Typography>

                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: "end", }} >
                                    <Typography variant="h6" color="primary" >
                                        BOOKING DATE
                                    </Typography>
                                    <Typography variant="h6" fontWeight="bold">
                                        {formattedDate}
                                    </Typography>

                                    <Typography mt={4} sx={{ textAlign: "end" }}>
                                        <div style={{ color: "#0071bc", fontWeight: "bold" }}>
                                            SUBPACKAGE:{" "}
                                        </div>
                                        <span style={{ color: "#666" }}>{subpackageName}</span>
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 1, height: '3px', background: "linear-gradient(to right, #DCB325, #D12E2E)" }} />
                        </Box>

                        {/* Amount Details Section */}
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="start"
                            alignItems="center"
                            sx={{ gap: 1.5 }}
                        >
                            {/* Transaction ID */}
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="start"
                                width={{ xs: "100%", sm: "600px" }}
                                height="auto"
                                sx={{ gap: 0.5 }}
                            >
                                <Typography
                                    variant="h6"
                                    textAlign="center"
                                    sx={{
                                        fontSize: { xs: "18px", sm: "14px" },
                                        fontWeight: "medium",
                                        wordBreak: "break-word",
                                    }}
                                >
                                    <div style={{ fontWeight: 500 }}>TRANSACTION ID: </div>
                                    <span style={{ fontSize: 14 }}>{bookingData?.paymentId}</span>
                                </Typography>
                            </Box>

                            {/* Subtotal, GST, and Total */}
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="start"
                                sx={{ px: 2 }}
                                spacing={3}
                            >
                                {/* Subtotal */}
                                <Grid item xs={4}  >
                                    <Box display="flex" flexDirection="column" sx={{ gap: 0.5 }}>
                                        <Typography
                                            variant="h6"
                                            color="#0071bc"
                                            fontWeight="medium"
                                            textAlign="left"
                                            fontSize={16}
                                        >
                                            SUBTOTAL
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            color="#666"
                                            textAlign="left"
                                        >

                                            <span style={{ fontWeight: "medium" }}>₹{subtotal.toFixed(2)}</span>
                                        </Typography>
                                    </Box>
                                </Grid>

                                {/* GST */}
                                <Grid item xs={4} >
                                    <Box display="flex" flexDirection="column" sx={{ gap: 0.5 }}>
                                        <Typography
                                            variant="h6"
                                            color="#0071bc"
                                            fontWeight="medium"
                                            textAlign="left"
                                            fontSize={16}
                                        >
                                            GST
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            color="#666"
                                            textAlign="left"
                                        >
                                            <span style={{ fontWeight: "medium" }}>₹{gst.toFixed(2)}</span>
                                        </Typography>
                                    </Box>
                                </Grid>

                                {/* Total Amount */}
                                <Grid item xs={4} display="flex" justifyContent="flex-start">
                                    <Box display="flex" flexDirection="column" sx={{ gap: 0.5 }}>
                                        <Typography
                                            variant="h6"
                                            color="#0071bc"
                                            fontWeight="medium"
                                            textAlign="left"
                                            fontSize={12}
                                        >
                                            TOTAL AMOUNT
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            color="#010101"
                                            fontWeight="bold"
                                            textAlign="left"
                                        >
                                            ₹{totalAmount.toFixed(2)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    {/* Action Buttons */}
                    <Box sx={{ textAlign: "center", mt: -6, }}>
                        <Button
                            variant="contained"
                            onClick={handleDownloadPDF}
                            sx={{
                                background: "linear-gradient(to left, #7f9c40, #165b82)",
                                fontSize: "1.25rem",
                                px: 5,
                                py: 1.1,
                                color: "white",
                                borderRadius: 2,
                            }}
                        >
                            Download Ticket
                        </Button>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                mt: 1.2,
                                mb: 0.5
                            }}
                        >
                            <Typography variant="body1" fontSize={20} onClick={sendPdfToServer} sx={{ cursor: "pointer" }}>
                                Get your Tickets on WhatsApp
                            </Typography>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </div>
    );
};



export default NewTicket